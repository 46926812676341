:root {
  --photo-width: 350px;
  --photo-height: 500px; 
}

.match-swipe-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.match-swipe-header {
  position: relative;
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #282c34;
  color: rgb(255, 255, 255);
  z-index: 21;
}

.match-swipe-header h1 {
  margin: 0;
  font-size: 24px;
}

.swipe-buttons {
  bottom: 150px;  
  width: 300px;
  z-index: 10;
  display: flex;
  justify-content: space-around;
}

.nope {
  width: 100px;
  height: 100px;
  border: none;
  background-image: url('/public/images/nope.png');
  cursor: pointer;
  background-size: cover;
  background-color: transparent; /* 背景を透過 */
}

.like {
  width: 100px;
  height: 100px;
  border: none;
  background-image: url('/public/images/like.png');
  cursor: pointer;
  background-size: cover;
  background-color: transparent; /* 背景を透過 */
}


.match-swipe-body {
  background-color: #ffffff;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 25;
}

.no-match-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.no-match-message {
  top: 20%;
  margin-bottom: 2000px;
  /* position: relative; */
  font: optional;
  font-size: 100%;
}

.no-match-Prof-container {
  /* position: relative; */
  align-items: center;
  justify-content: center;
  top: 30%;
  left: 36%;
}

.no-match-profile-image {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 21;
}

.no-match-ripple {
  /* position: relative; */
  /* top: 400%;
  left: 0%; */
  margin-top: -110px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(255, 105, 180, 0.5);
  border: 2px solid rgba(255, 105, 180, 0.8);
  animation: ripple 2s infinite;
  z-index: 10;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.swipe-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.photo-container-current,
.photo-container-next {
  position: absolute;
  width: var(--photo-width);
  height: var(--photo-height);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 20px 12px 2px rgba(0, 0, 0, 0.1);
}

.photo-container-current img,
.photo-container-next img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 20px 12px 2px rgba(0, 0, 0, 0.1);
}

/* スワイプした時の写真アニメーション */
.swipe-Like {
  transform: translate(100%, -100%) rotateZ(-15deg);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.swipe-Nope {
  transform: translate(-100%, -100%) rotateZ(15deg);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-in-R {
  animation: slide-in-R 0.3s ease-in-out;
}

.slide-in-L {
  animation: slide-in-L 0.3s ease-in-out;
}

@keyframes slide-in-R {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-in-L {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* ズームインアニメーション */
.zoom-in {
  animation: zoom-in 0.3s ease-in-out;
}

@keyframes zoom-in {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.reset-position {
  transform: translateX(0);
  opacity: 0;
  transition: none;
}

.hidden {
  display: none;
}

.show {
  opacity: 1;
  transition: opacity 0.01s ease-in-out;
}

.match-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.match-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  animation: popup 0.5s ease-in-out;
}

.match-popup-content h2 {
  margin: 0 0 10px;
  color: black;
}

.match-popup-content img {
  width: 50%;
  border-radius: 10px;
}

@keyframes popup {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
