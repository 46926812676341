.match-container {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: pop-up 0.3s ease-in-out;/* アニメーションの変更 */
  z-index: 10;
}

.home-back {
 animation: underApp-Home 0.3s forwards;
}

@keyframes underApp-Home {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.2) translate(-50%, -100%); /* 目的の座標を指定 */
    opacity: 0;
  }
}

.match-menu {
  position: relative; /* 相対位置に設定 */
  background-color: #ffffff;
  display: flex;
  /* border: 1px solid #000; */
  flex-direction: row;
  z-index: 10;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 10%;
  /* margin-top: 10px;  */
  /* 余白を追加してメッセージ入力フィールドとのスペースを確保 */
}


.match-button {
width: 50px;
height: 50px;
margin: 20px;
border: none;
border-radius: 10%;
background-size: cover;
background-repeat: no-repeat;
cursor: pointer;
background-color: transparent; 
/* 背景を透過 */
}

.id-icon {
  background-image: url('/public/images/id.png'); /* Id ボタンの画像パス */
}

.id-swipe {
  background-image: url('/public/images/swipe.png'); /* Swipe ボタンの画像パス */
}

.id-chat {
  background-image: url('/public/images/chat.png'); /* Chat ボタンの画像パス */
}


.match-button:hover {
  /* background-color: darkred; */
  background-color: rgb(103, 103, 109);
}

.match-footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 0, 0, 0);
}



/* アニメーションの変更 */
@keyframes pop-up {
  0% {
    transform: translateY(40px) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1.0);
  }
  80%, 100% {
    opacity: 1;
  }
}
 