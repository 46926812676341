* {
  font-family: 'Roboto', sans-serif;
  /* font-weight: 100; */
  user-select: none; /* ドラッグして選択→青くなるを無効化 */
}

.App{
  text-align: center;
}

.App-header {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: repeat; 
  background-color: rgb(0, 0, 0); 
}


.login-info {
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
  color: #000000;
  font-size: 80%;
  /* background-color: #333; */
  border-radius: 10px;
  /* width: 30%; */
}

.login-info h3{
  font-size: 80%;
  left: 0;
  margin-bottom: -0%;
}

.phone-cover{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  /* background-image: url('/public/images/HijitsuPhoneHack.png'); */
  /* background-color: #007bff; */
  pointer-events: none;
  /* z-index: 2000; */
}

/* 背景画像の基本設定 */
.responsive-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* PC版: ピンクと黄色部分を表示 */
@media (min-width: 1024px) {
  .responsive-background {
    object-fit: cover;
    object-position: center; /* 中央揃え */
  }
}

/* モバイル版: 黄色部分のみを表示 */
@media (max-width: 1024px) {
  .responsive-background {
    object-fit: cover;
    object-position: center center; /* 必要に応じて調整 */
    /* clip-path: inset(0 20% 0 20%);  */
  }
}


/* スマホ全体*/
.phone-master {
  position: absolute;
  width: 20%; 
  height: auto; /* 高さは自動で計算 */
  aspect-ratio: 6 / 13; /* 比率を固定 */
  z-index: 18;
  display: flex;
  justify-content: center; 
  align-items: flex-end; 
  /* background-color: rgba(170, 9, 9, 0.7);  */
  /* transform: rotate(6deg); */
}

/* スマホの画面 */
.phone {
  position: absolute;
  width: 100%;
  height: 100%;
  /* border-radius: 1.4em; */
  background-color: #000000;
  /* background-image: url('/public/images/PHONEbackground.jpg'); */
  background-image: url('/public/images/papet.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transform-origin: center center;
  overflow: hidden;
  z-index: 10;
  /* filter: drop-shadow(-80px 0px 2px rgba(51, 12, 12, 0.4));  */
  /* filter: brightness(0.95) saturate(1); */
}

/* .crack-overlay {
  position: absolute;
  top: 170px;
  left: -2.5px;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/crack.png');
  background-repeat: no-repeat;
  pointer-events: none;
  filter: brightness(1.4) contrast(2);
  opacity: 0.7;
  z-index: 40;
} */
 
/* メインスクリーン 画面の反射*/
.phone:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/Phone-reflection.jpg');
  /* background-color: rgba(50, 83, 50, 1);  */
  background-size: cover;
  mix-blend-mode: screen;
  pointer-events: none;
  opacity: 40%;
  z-index: 100;
}

.status-bar {
  position: absolute;
  top: 0px;
  left: 0;
  width: 93%;
  height: 24px;
  display: flex;
  justify-content: space-between; /* 左右の要素を左右に配置 */
  align-items: center;
  padding: 4% 4%; /* 上下の余白をなくして左右の余白のみ設定 */
  color: #333;
  z-index: 50;
  font-size: 15px;
  mix-blend-mode: darken;
}

/* .status-bar .time {

} */

.status-bar .right-icons {
  display: flex;
  align-items: center;
  gap: 0%; /* Wi-Fiとバッテリーの間隔を最小化 */
}

.battery-icon {
  position: relative;
  align-items: center;
  background-color: #ffffff;
}

.wifi-icon{
  padding-bottom: 5%;
  position: relative;
  background-color: #ffffff;
}

/* .phone:before {
  content: '';
  display: block;
  width: 120px;
  height: 30px;
  background: #333;
  border-radius: 10px;
  position: absolute;
  top: -12px;
} */

.clock {
  position: absolute;
  top: 8%;
  text-align: center;
  width: 100%;
  color: #000;
  /* pointer-events: none; */
}

.clock h1 {
  position: relative;
  font-size: 300%;
  margin-bottom: -4%;
  font-family: 'Roboto', Arial, sans-serif;
}

.clock p {
  margin-top: 0px;
  font-size: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: bold;
}

/* アプリ全体の並べ方 */
.applications {
  position: absolute;
  /* background-color: #118b35; */
  top: 24%;
  width: 95%;
  padding: 10%;
  display: grid;
  grid-template-columns: repeat(4,1fr); 
  background-blend-mode: overlay;
  /* background-color: rgb(64, 64, 64); */
  justify-items: center; /* アイコンを中央揃え */
  align-content: center; /* 縦方向の揃えを中央に */
}


.app-icon {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 25%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  /* overflow: hidden; */
}

.app-icon img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  overflow: hidden;
}

.unread-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  overflow: visible;
}


.app-icon:hover {
  background-color: #a0a0a0de;
}

.app-icon.messages {
  background: linear-gradient(135deg, #57c277, #118b35);
  background-blend-mode: overlay;
}

.app-name {
  font-size: 12px;
  color: rgba(0, 0, 0 ,0);
  font-weight: bold;
}

/* Calendar ウィジェット Styles */
.calendar-widget {
  position: absolute;
  top: 8%;
  width: 95%;
  height: 18%;
  background: #f7f7f7;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  z-index: 0;
}

.calendar-container{
  width: 100%;
  height: 100%;
  padding: 12px;
  display: flex;
  /* background-color: #118b35; */
}

.calendar-widget-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;;
}

.calendar-widget-date {
  font-size: 100%;
  font-weight: lighter;
  color: #333;
  /* background-color: #28a745; */
}

.calendar-widget-weekday {
  font-size: 40%;
  color: #666;
}

.calendar-widget-tasks {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10%;
}

.calendar-widget-task {
  width: 95%;
  font-size: 38%;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 1%;
  text-align: left; /* テキストを左揃えに */
  display: flex; /* フレックスボックスに変更 */
  align-items: center; /* アイコンとテキストを縦方向に中央揃え */
}


.calendar-widget-task::before {
  content: "";
  display: inline-block;
  width: 16px; /* SVGアイコンの幅 */
  height: 16px; /* SVGアイコンの高さ */
  background-image: url('/public/images/check-icon.svg'); 
  background-size: cover; /* サイズをボックスに収める */
  background-repeat: no-repeat;
  margin-right: 8px; /* テキストとの余白 */
}

/* MAPウィジェットのスタイル */
.google-map-widget {
  position: absolute;
  align-self: flex-start;
  left: 2.5%;
  top: 50%;
  width: 200px; /* ウィジェットの幅 */
  height: 200px; /* ウィジェットの高さ */
  background-color: transparent; /* 背景を透明に */
  cursor: pointer;
  overflow: hidden; /* 子要素がはみ出さないようにする */
  border-radius: 15%; /* 角丸を適用 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 影を追加 */
}

.google-map-widget img {
  display: block; /* 画像をブロック要素として扱う */
  width: 100%; /* 親要素の幅にフィット */
  height: 100%; /* 親要素の高さにフィット */
  object-fit: cover; /* 親要素内に画像を完全に収める */
  border-radius: 15%; /* 画像にも角丸を適用 */
}

.phone-dock {
  position: absolute;
  bottom: 2%; 
  width: 95%; 
  height: 11%;
  background: linear-gradient(to bottom, rgba(131, 131, 131, 0.1), rgba(255, 255, 255, 0.349));
  backdrop-filter: blur(30px);
  border-radius: 25px; /* ドックの丸み */
  display: flex;
  justify-content: center; /* アイコンを中央に配置 */
  align-items: center; /* 縦方向の中央揃え */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 影を追加 */
  z-index: 0;
}

.dock-icon {
  position: relative;
  width: 80px; /* アイコンの幅 */
  height: 80px; /* アイコンの高さ */
  background-color: #ffffff;
  border-radius: 25%;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  z-index: 1;
}

.dock-icon-img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* 画像の比率を維持 */
}

.dock-icon:hover {
  background-color: rgba(220, 220, 220, 0.9); /* ホバー時の色変更 */
}

.popup {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
}

.popup.show {
  bottom: -100%;
  transition: transform 0.5s ease-in-out;
  transform: translateY(-100%);
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.home-button {
  position: absolute;
  background-color: transparent; /* 元のグレー部分 */
  width: 100%; /* 元の幅 */
  height: 100%; /* 元の高さ */
  border: none;
  padding: 0px;
  border-radius: 100px;
  z-index: 15;
  cursor: pointer;
  text-align: center;
}

.home-button::after {
  content: "";
  position: absolute;
  top: 50%; /* 元のボタンの中心 */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%; /* 透明範囲の横幅を広げる */
  height: 30%; /* 透明範囲の縦幅を広げる */
  background-color: rgb(204, 204, 204); /* 透明 */
  mix-blend-mode: difference;
  border-radius: 100px; /* 透明範囲も丸みを付ける */
  z-index: -1; /* 背面に配置 */
  pointer-events: auto; /* 透明部分もクリック可能にする */
}

@media (max-width: 768px) {
  html, body {
    height: 100%; /* ページ全体の高さを100%に固定 */
    margin: 0; /* 余白を削除 */
    padding: 0;
    /* background-image: url('/public/images/SF版背景.webp');  */
    background-color: #ffffff;
    background-size: cover;
    background-position: center; /* 背景画像を中央に配置 */
    background-repeat: no-repeat;
    overflow-x: auto;
    overflow-y: visible;
  }

  .phone-master {
    width: 300px; /* 幅を固定 */
    height: auto; /* 高さは自動で計算 */
  }
  
  .applications {
    /* background-color: rgb(64, 64, 64); */
   } 

   .app-icon {
    width: 55px; 
    height: 55px;
    border: 0px;
    cursor: pointer;
    /* margin-bottom: 15%; */
    }

    .dock-icon {
      width: 50px; /* アイコンの幅 */
      height: 50px; /* アイコンの高さ */
      background-color: #ffffff !important;
    }
}