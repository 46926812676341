.oba-assistant-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 300px;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* 最前面に表示 */
  }
  
  .oba-assistant-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .oba-assistant-buttons button {
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .oba-assistant-buttons button:hover {
    background-color: #0056b3;
  }
  