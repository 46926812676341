/* カレンダーコンテナ */
.calender-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: pop-up 0.3s ease-in-out;
  z-index: 10;
}

/* アニメーション */
.calender-container.apphomeback {
  animation: calenderhomeback 0.3s ease-in-out forwards;
}

@keyframes calenderhomeback {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.052);
    top: -30%;
    left: 0%;
    opacity: 20;
  }
}

/* カレンダー本体 */
.calendar-body {
  position: relative;
  /* position: absolute; */
  top: 40px;
  width: 100%;
  height: 90vh;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* border-radius: 8px; */
}

/* ローディングインジケーター（ぐるぐる） */
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: auto;
  transform: translate(-50%, -50%);
  font-size: 100%;
  font-weight: bold;
  color: #000000;
  z-index: 999; /* カレンダーよりも前面に表示 */
  background-color: rgba(204, 232, 255, 0.5); /* 半透明の背景 */
  padding: 20px;
  border-radius: 8px; 
  white-space: nowrap;
}

/*タイトルの年と月 */
.fc .fc-toolbar-title {
  font-size: 100%;
  color: #000000;
  font-weight: 500;
}
/* 月移動ボタン */
.fc .fc-button-primary {
  background-color: #fff;
  color: #000;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  /* padding: 4px 8px; */
  font-size: 0.9rem;
}

.fc .fc-button:hover {
  background-color: #f1f3f4;
}


.fc-daygrid{
  margin-top: -4%;
  /* background-color: #1a73e8; */
  height: 70%;
}
/* カレンダー日付 */
.fc-daygrid-day-number {
  font-size: 60%;
  font-weight: normal;
  color: #5f6368;
}

/* 各イベント */
.fc-event-title,
.fc-event-time {
  color: #000000;
}

.fc-event {
  border: none;
  color: #1a73e8 !important;
  padding: 1% 1%;
  font-size: 50%;
  border-radius: 4px;
  transition: none !important; /* 余計なアニメーションを無効化 */
}

/* 曜日の文字色を黒に設定 */
.fc .fc-col-header-cell {
  color: black !important; /* 黒色に変更 */
  font-weight: 100;
  font-size: 80%;
}

.fc td, .fc th {
  border: none;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.fc td:last-child, .fc th:last-child {
  border-right: none;
}

/* イベント詳細モーダル */
.event-details-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  text-align: left; /* テキストを左寄せにする */
}

.event-details-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 左寄せ */
  position: relative; /* 閉じるボタンを右上に配置するため */
}

.event-details-title {
  color: #000;
  font-size: 120%;
  margin-bottom: 10px;
}

.event-details-date {
  font-size: 90%;
  color: #757575;
}

/* SVGアイコンのスタイル */
.menu-icon {
  display: block;
  margin: 5px 0;
  opacity: calc(10);
}

.menu-icon rect {
  fill: rgba(0, 0, 0, 0.3); /* 色を薄く（透明度30%）したグレー */
}

.event-details-description {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

.event-details-footer {
  margin-top: 20px;
  font-size: 12px;
  color: #757575;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 左寄せ */
}

.calendar-name {
  font-weight: bold;
}

.event-creator {
  margin-top: 5px;
}

/* 場所情報のスタイル */
.event-details-description p {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

.event-details-description strong {
  font-weight: bold;
  color: #333;
}

/* バツ印（閉じるボタン）のスタイル */
.event-details-close {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 5%;
  font-size: 150%;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.event-details-close:hover {
  color: #ff0000; /* ホバー時に赤色に変わる */
}

.event-details-close:focus {
  outline: none; /* フォーカス時のアウトラインを消す */
}



/* フッター部分 */
.shoot-game-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
