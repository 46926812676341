body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  }
  
  .youtube-studio-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    color: #000;
    display: flex;
    flex-direction: column;
    animation: pop-up 0.3s ease-in-out;
    /* border-radius: 1.4em; */
    z-index: 20;
  }
  
  .youtube-studio-header.YTHomeback {
    animation: YTHomeback 0.3s ease-in-out forwards;
  }
  
  @keyframes YTHomeback {
    0% {
        transform: scale(1) translate(0, 0);
        opacity: 1;
      }
      100% {
        transform: scale(0.2) translate(-200px, -100px); /* 目的の座標を指定 */
        opacity: 0;
      }
  }
  
  .header, .nav, .studio-footer {
    padding: 0.5rem 1rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .nav {
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .button {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    cursor: pointer;
    background-color: transparent;
  }
  
  .button:hover {
    background-color: #f3f4f6;
  }
  
  .main {
    flex: 1;
    padding: 1rem;
    overflow: auto;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .video-card {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
  }
  
  .video-preview {
    aspect-ratio: 16 / 9;
    background: #e5e7eb;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .studio-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }
  
