.cameraApp-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: pop-up 0.3s ease-in-out;
  /* border-radius: 1.4em; */
  z-index: 10;
}

.cameraApp-container.game-home-back {
  animation: game-homeBack 0.3s ease-in-out forwards;
}

@keyframes game-homeBack {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.2) translate(100px, 600px);
    opacity: 0;
  }
}

.camera-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  color: white;
}

.camera-header h1 {
  margin: 0;
}

.camera-body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  /* border-radius: 1.4em; */
}

.camera-body a-scene {
  width: 100%;
  height: 100%;
}

.camera-ui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.camera-top-bar {
  width: 100%;
  height: 11%;
  /* background-color: #000000; */
  opacity: 60%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  pointer-events: all;
}

.camera-bottom-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  pointer-events: all;
  position: absolute;
  bottom: 90px; /* シャッターボタンの上に配置 */
  width: 100%;
}

.camera-flash-icon, .camera-settings-icon {
  background: none;
  border: none;
  color: white;
  font-size: 1em;
}

.camera-mode {
  background: none;
  border: none;
  color: white;
  font-size: 50%;
  padding: 0 10px;
}

.camera-mode.selected {
  color: yellow;
}

.camera-shutter-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: white;
  border: none;
  border-radius: 50%;
  pointer-events: all;
  cursor: pointer;
  
}

.camera-footer {
  position: absolute; 
  bottom: 0%;
  width: 100%;
  height: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 10;
  opacity: 50%;
}

.camera-flash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  z-index: 100;
  pointer-events: none;
}

.camera-flash.flash {
  opacity: 1;
  animation: flash-animation 0.5s ease-in-out;
}

@keyframes flash-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.popup-DL {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 200px;
  color: #000000;
  background-color: transparent;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-DLimage {
  position: absolute;
  width: 300px; /* 画像の幅 */
  height: auto; /* 高さを自動調整 */
  object-fit: contain;
  
  animation: take_a_shot_DL 3s ease forwards;
}

/* アニメーション終了後の固定画像 */
.final-image-style {
  position: absolute;
  bottom: 3%;
  left: 55px;
  width: 50px; /* 正方形のサイズ */
  height: 50px;
  border-radius: 50%; /* 画像を丸く */
  object-fit: cover; /* 画像のトリミング */
}

/* アニメーション定義 */
@keyframes take_a_shot_DL {
  0% {
    opacity: 0;
    transform: translate(0%, 0%) scale(0); /* 中央で小さく */
  }
  80% {
    opacity: 1;
    transform: translate(0%, 0%) scale(1.4); /* 中央でふわっと表示 */
    border-radius: 0%; 
    width: 300px; /* 画像の幅 */
    height: auto; /* 高さを自動調整 */
  }
  100% {
    opacity: 0;
    transform: translate(-150px, 410px) scale(0.3); /* 左下に移動 */
    border-radius: 50%; 
    width: 170px; 
    height: 170px; 
    object-fit: cover; 
  }
}

.clickable {
  cursor: pointer;
  pointer-events: auto;
}
  

.a-enter-vr {
  display: none !important;
}

@media (max-width: 768px) {
  @keyframes take_a_shot_DL {
    0% {
      opacity: 0;
      transform: translate(-48%, 0%) scale(0); /* 中央で小さく */
    }
    80% {
      opacity: 1;
      transform: translate(-14%, 0%) scale(1); /* 中央でふわっと表示 */
      border-radius: 0%; 
      width: 300px; /* 画像の幅 */
      height: auto; /* 高さを自動調整 */
    }
    100% {
      opacity: 0;
      transform: translate(-75px, 252px) scale(0.3); /* 左下に移動 */
      border-radius: 50%; 
      width: 170px; 
      height: 170px; 
      object-fit: cover; 
    }
  }

  .final-image-style {
    position: absolute;
    bottom: 4%;
    left: 40px;
    width: 50px; /* 正方形のサイズ */
    height: 50px;
    border-radius: 50%; /* 画像を丸く */
    object-fit: cover; /* 画像のトリミング */
  }
}