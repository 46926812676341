.map-app-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: pop-up 0.3s ease-in-out;
    z-index: 10;
  }
  
  .map-app-container.maphomeback {
    animation: maphomeback 0.3s ease-in-out forwards;
  }
  
  @keyframes maphomeback {
    0% {
      transform: scale(1) translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: scale(0.05) translate(-50%, -50%);
      opacity: 0;
    }
  }


  .image-field {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%; /* 初期状態は非表示 */
    background-color: rgba(141, 0, 0, 0.95);
    z-index: 150;
    overflow: hidden; /* 画像がはみ出ないように */
    transition: height 0.5s ease; /* 高さのアニメーションを適用 */
  }
  
  .image-field.slideanim {
    height: 200%; /* クリック時に高さを100%に拡大 */
  }
  

  .field-image {
    width: 100%;
    height: 100%;
    background-color: aqua;
  }
  
        .close-button {
            position: absolute;
            top: 10%;
            right: 10px;
            background: transparent;
            border: none;
            font-size: 24px;
            cursor: pointer;
            color: #ffffff;
            background-color: rgba(0, 0, 0, 0.342);
        }
        
        .close-button:hover {
            color: red;
        }


  .map-body {
    position: relative;
    width: 100%;
    height: 97%;
    bottom: 2%;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
    z-index: 11;
  }
  
  .map-view {
    height: 100%;
    font-size: 12px;
    transition: height 0.5s ease;
    z-index: 100;
  }
  
  .map-view.with-image {
    height: 40%; 
  }

  .leaflet-control-attribution {
    font-size: 13px; /* お好みのサイズに変更 */
    bottom: 0;
    background: rgba(255, 255, 255, 0.6); /* 背景を白半透明にする例 */
    border-radius: 4px; /* 角を丸める */
  }
  

  .custom-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  
  .marker-icon {
    width: 20px;
    height: 20px;
    background-color: blue;
    border: 1px solid white;
    border-radius: 50%;
    position: relative;
  }
  
  .marker-icon .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid white;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
    .street-view-toggle{
        position: absolute;
        top: 10%;
        right: 2%;
        width: 20%;
        z-index: 120;
    }

    .return-button {
        position: absolute;
        bottom: 10%;
        right: 2%;
        width: 50px;
        height: 50px;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 50%;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        z-index: 120;
    }
    
    .return-button:hover {
        background-color: #f0f0f0;
    }


  .map-footer {
    position: absolute;
    bottom: 0;
    height: 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color:rgb(255, 255, 255) ;
    z-index: 130;
  }

  .a-enter-vr {
    display: none !important;
  }
  