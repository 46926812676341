/* FullScreenImage.css */
.full-screen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;  /* 他の UI より上 */
}


/* BlackBlink.css */
.blackblink {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  pointer-events: none;
}

.blackblink-top,
.blackblink-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: black;
}

/* 初期状態（両パネルとも画面外） */
.blackblink.closing .blackblink-top {
  animation: topClose 1s forwards;
}
.blackblink.closing .blackblink-bottom {
  animation: bottomClose 1s forwards;
}

/* closing 時の上下パネル */
.blackblink.closing .blackblink-top {
  animation: topClose 1s forwards;
}
.blackblink.closing .blackblink-bottom {
  animation: bottomClose 1s forwards;
}

/* opening 時の上下パネル */
.blackblink.opening .blackblink-top {
  animation: topOpen 0.5s forwards;
}
.blackblink.opening .blackblink-bottom {
  animation: bottomOpen 0.5s forwards;
}


/* 上部パネル */
@keyframes topClose {
  0% { top: -50%; }
  25% { top: 0; }
  50% { top: -25%; }
  75% { top: 0; }
  100% { top: 0%; }
}

/* 下部パネル */
@keyframes bottomClose {
  0% { bottom: -50%; }
  25% { bottom: 0; }
  50% { bottom: -25%; }
  75% { bottom: 0; }
  100% { bottom: 0%; }
}

/* opening アニメーション */
@keyframes topOpen {
  0% { top: 0; }
  100% { top: -50%; }
}

@keyframes bottomOpen {
  0% { bottom: 0; }
  100% { bottom: -50%; }
}


/* 上部パネルの下端にぼかしを適用 */
.blackblink-top::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px; /* ぼかし部分の高さ */
  background: black;
  filter: blur(5px); /* ぼかし適用 */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* 下部パネルの上端にぼかしを適用 */
.blackblink-bottom::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px; /* ぼかし部分の高さ */
  background: black;
  filter: blur(5px);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* closing のぼかし強調 */
.blackblink.closing .blackblink-top::after,
.blackblink.closing .blackblink-bottom::after {
  opacity: 1;
}

/* opening のぼかし解除 */
.blackblink.opening .blackblink-top::after,
.blackblink.opening .blackblink-bottom::after {
  opacity: 0;
}