.match-chat-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Center から変更 */
  z-index: 10;
}

.match-chat-header {
  position: relative;
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #282c34;
  border-color: #282c34;
  border-width: 10px;
  color: rgb(255, 255, 255);
  z-index: 0;
}

.match-chat-title {
  position: relative;
  display: flex;
  top: 0;
  flex-direction: column; /* 縦並びに変更 */
  align-items: center; /* 中央揃え */
  justify-content: center; /* 縦方向でも中央揃え */
  background-color: #007bff;
  padding: 10px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  border: solid #007bff;
  color: white;
  /* z-index: 1; */
}

.match-chat-title .user-photo {
  width: 60px; /* 画像サイズを調整 */
  height: 60px;
  border-radius: 50%; /* 丸い写真 */
  margin-bottom: 10px; /* 名前との間に少し余白 */
}

.match-chat-title .user-name {
  font-size: 15px;
  text-align: center;
  margin: 0;
}

.photo-grid{
  /* position: relative; */
  z-index: 0;
}

.back-button {
  position: absolute;
  left: 0;
  top: 5%;
  background-color: transparent;
  vertical-align: middle;
  border: none;
  color: white;
  font-size: 80%;
  cursor: pointer;
  z-index: 3;
}

.back-button svg {
  margin-right: 0x; /* アイコンとテキストの間にスペースを追加 */
  vertical-align: middle; /* アイコンをテキストの中央に揃える */
}

.match-chat-body {
  background-color: #f0f0f0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* height: calc(100% - 110px);  */
  height: 100%;
  /* Headerと入力フィールドの高さを考慮 */
  overflow-y: auto;
  animation: slide-in-L 0.3s ease-in-out;
}

.messages-list {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding-bottom: 60px;
}

.message.user {
  position: relative;
  align-self: flex-start;
  margin-right: 0;
  border-radius: 10px 10px 0px 10px;
  background-color: #007bff;
  color: #ffffff;
  text-align: left;
  margin-left: auto;
}

.message.bot {
  position: relative;
  align-self: flex-start;
  border-radius: 10px 10px 10px 0px;
  background-color: #d1d1d1;
  color: #000000;
  text-align: left;
  margin-right: auto;
}

.message-date{
  color: #424242;
  font-size: 70%;
}

.message {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  max-width: 70%; /* 吹き出しの最大幅を70%に設定 */
  width: fit-content; /* テキスト量に応じて幅を自動調整 */
  word-wrap: break-word;
}

.message a {
  color: #007bff;
  text-decoration: underline;
}

.message a:hover {
  color: #0056b3;
}


.bot-photo {
  width: 40px; /* 写真の幅 */
  height: 40px; /* 写真の高さ */
  border-radius: 50%; /* 丸い写真にする */
  object-fit: cover; /* 写真のアスペクト比を維持 */
}

.message-input {
  position: relative; /* 相対位置の上に固定 */
  bottom: 0%; /* 親要素（.match-menu）の上に配置 */
  width: 100%;
  padding: 10px;
  box-sizing: border-box; /* パディングを含めたサイズ調整 */
  background-color: #ffffff; /* 入力フィールドの背景色 */
  z-index: 11; /* 他の要素の上に表示 */
}

.message-input input {
  flex: 1;
  padding: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px; 
  box-sizing: border-box; /* パディングを含めたサイズ調整 */
  font-size: 16px;
}

.message-input button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.message-input button svg {
  width: 24px;
  height: 24px;
}

.message-input button:hover {
  background-color: #0056b3;
}

.verification-box h1{
 font-size: 35px;
 color: #000000;
}

.verification-box {
  position: relative;
  top: -25%;
  background-color: rgba(255, 255, 255, 0.85); /* 背景を透過しつつ、少し白くする */
  padding: 20px;
  color: #4d4d4d;
  font-size: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 20px;
}

.upload-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.upload-button svg {
  margin-right: 10px;
}

.upload-button:hover {
  background-color: #0056b3;
}

.upgrade-popup{
  color: #000000;
}

.review-message {
  position: absolute;
  top: 40%;
  height: 30%;
  background-color: rgba(243, 245, 223, 0.952); /* 背景色を設定 */
  padding: 15px;
  border: 1px solid #caa1a1;
  border-radius: 5px;
  margin: 20px;
  width: calc(100% - 40px); /* 画面幅に応じて適切に調整 */
  text-align: center;
  z-index: 1000;
}

.review-message h1 {
  color: rgb(238, 182, 0);
  font-size: 30px;
  margin-bottom: 10px;
}

.review-message p {
  color: rgb(0, 0, 0);
  font-size: 20px;
}

.upgrade-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background: linear-gradient(135deg, #34c1a1, #ff5f6d); /* グラデーション背景 */
  color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-out;
}

.upgrade-popup h1 {
  font-size: 130%;
  font-weight: bold;
  margin: 0;
  padding: 10px 0;
}
.upgrade-popup h2 {
  font-size: 80%;
  font-weight: bold;
  margin: 0;
  padding: 10px 0;
}

.upgrade-popup p {
  font-size: 80%;
  margin: 0;
  padding: 10px 0;
}

.upgrade-popup .plans {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.upgrade-popup .plan {
  text-align: center;
  padding: 10px;
  background-color: white;
  color: #333;
  border-radius: 10px;
  width: 28%;
  font-size: 0.9em;
}

.upgrade-popup .plan .duration {
  font-weight: bold;
  color: #ff5f6d;
  /* font-size: clamp(0.8em, 1.5vw, 1em);  フォントサイズを自動で調整  */
  white-space: nowrap;
}

.upgrade-popup .plan .price {
  font-weight: bold;
  font-size: 60%;
}

.upgrade-popup .plan .discount {
  color: #34c1a1;
  font-size: 0.8em;
  white-space: nowrap;
}

.upgrade-popup .action-button {
  background-color: #ff5f6d;
  color: white;
  border: none;
  padding: 12px 20px;
  margin-top: 15px;
  border-radius: 30px;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
}

.upgrade-popup .cancel-button {
  color: #333;
  font-size: 0.9em;
  margin-top: 10px;
  cursor: pointer;
}

/* ポップアップのフェードインアニメーション */
@keyframes fadeIn {
  from { opacity: 0; transform: translate(-50%, -60%); }
  to { opacity: 1; transform: translate(-50%, -50%); }
}
