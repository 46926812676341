.BankApp-container {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: pop-up 0.3s ease-in-out;
  /* border-radius: 1.4em; */
  z-index: 20;
}

.BankApp-container.BankHomeback {
  animation: BankHomeback 0.3s ease-in-out forwards;
}

@keyframes BankHomeback {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.2) translate(50%, -100%);  /* 目的の座標を指定 */
    opacity: 0;
  }
}

.bank-header {
  position: absolute;
  top: -4%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
  padding-top: 20%;
  background-color: #d32f2f;
  color: white;
}

.bank-header h1 {
  margin: 0;
}

.bank-body {
  position: absolute;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.account-summary {
  text-align: center;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  height: 90%;
  background-color: #f0f0f0;
  border: 2px solid #000000;
  border-radius: 4px;
}

.account-summary h2 {
  margin: 10px 0;
  font-size: 24px;
  color: #333;
}

.detail-button {
  padding: 10px 20px;
  font-size: 80%;
  background-color: #d32f2f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 3%
}

.transaction-buttons {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.transaction-buttons button {
  padding: 10px;
  font-size: 60%;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.bank-footer {
  position: absolute;
  width: 100%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #d32f2f;
}

.popup {
  position: absolute;
  bottom: 20px;
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  /* border-radius: 5px; */
  animation: fadeInOut 2s ease-in-out;
}

/* 明細ポップアップ用スタイル */

.transaction-popup {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  background-color: white;
  border: 1px solid #ddd;
  /* border-radius: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
  padding: 20px;
  z-index: 10;
  color: #000000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.transaction-list {
  font-size: 50%;
  display: flex;
  flex-direction: column;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.negative {
  color: red;
}

.positive {
  color: green;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}


@media (max-width: 768px) {
  .bank-body {
    top: 15%;
  }
}