.PhotoApp-wrapper {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: calc(var(--vh) * 100); /* ビューポートの高さを確保 */
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  animation: pop-up 0.3s ease-in-out;
  z-index: 10;
}

.PhotoApp-wrapper.PhotoApp-homeback {
  animation: PhotoApp-homeback 0.3s ease-in-out forwards;
}

@keyframes PhotoApp-homeback {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.2) translate(-100%, -100%); /* 目的の座標を指定 */
    opacity: 0;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.9);
}

.PhotoApp-container {
  position: absolute;
  top: 7%;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
}



.photo-date-groups {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-date-group {
  width: 90%;
  margin-bottom: 20px;
}

.photo-date {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
  text-align: left;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 150px;
  gap: 6px;
  margin: 0%;
  margin-left: -3%;
  padding: 0;
  width: 100%;
  height: auto;
}

  
  .photo-item img {
    width: 150px;
    height: 150px;
    object-fit: cover; 
    cursor: pointer;
  }
  
  .photo-fullscreen {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .photo-fullscreen img {
    max-width: 100%;
    max-height: 100%;
  }
  

  .photo-footer {
    position: absolute;
    width: 100%;
    bottom: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 10;
    border-radius: 1.4em;
  }
  
  
  .video-player {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
  }

  .video-thumbnail {
    width: 150px;
    height: 150px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: #333;
    font-weight: bold;
    cursor: pointer;
    position: relative;
  }
  
  .video-duration {
    position: absolute;
    bottom: 5px; /* 下端から5px */
    right: 5px; /* 右端から5px */
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 0.8em;
    padding: 2px 4px;
    border-radius: 3px;
  }
  

  /* スクロールバー全体を非表示にする */
::-webkit-scrollbar {
  width: 8px; /* スクロールバーの幅 */
  visibility: hidden; /* デフォルトでスクロールバーを非表示に */
}

/* スクロールバーのつまみ部分 */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.8); /* つまみ部分の色 */
  border-radius: 10px; /* 角を丸くする */
}

/* スクロールバーのつまみ部分にホバーしたとき */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.8); /* ホバー時の色 */
}

@media (max-width: 768px) {
  /* .PhotoApp-container {
    position: absolute;
    top: 15%;
    padding-bottom: 200px;
  } */

  .photo-grid {
    grid-auto-rows: 100px;
    gap: 4px; 
    margin: 0; 
    padding: 0; 
}
  
  .photo-item img {
    width: 100px;
    height: 100px;
    object-fit: cover; 
    cursor: pointer;
  }

  .video-thumbnail {
    width: 100px;
    height: 100px;
  }

  .photo-footer {
    position: absolute;
    width: 100%;
    bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 10;
    border-radius: 1.4em;
  }
}