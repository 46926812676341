.user-list-container {
  padding: 20px;
  width: 100%;
  height: auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  text-align: left; /* 全体を左揃えに */
  color: black; /* 文字を黒く */
}

.user-list {
  list-style-type: none;    
  padding: 0;
  margin: 0;
  text-align: left; /* 全体を左揃えに */
}

.user-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left; /* 全体を左揃えに */
}

.user-item:hover {
  background-color: #e0e0e0;
}

.user-photo {
  width: 70px; /* サイズは適宜調整 */
  height: 70px; /* サイズは適宜調整 */
  border-radius: 50%; /* 丸くトリミング */
  margin-right: 10px;
  object-fit: cover;
}

.user-item p {
  margin: 0;
  font-size: 23px;
  font-weight: 500;
  color: #333333;
  text-align: left; /* 全体を左揃えに */
  padding: 15px;
}

.match-title {
  color: rgb(0, 0, 0); /* 文字を黒く */
  font-size: 100%; /* サイズを10pxに */
  margin: 0;
  text-align: left; /* 全体を左揃えに */
}

.no-match-message {
  color: black; /* 文字を黒く */
  font-size: 20px; /* サイズを10pxに */
  margin: 0;
  text-align: left; /* 全体を左揃えに */
}


@media (max-width: 768px) {

}