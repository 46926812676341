/* コンテナ */
.ma-message-app-container {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgb(175, 64, 64);
  overflow: hidden;
  animation: pop-up 0.3s ease-in-out;
  z-index: 10;
}

.ma-message-app-homeback {
  animation: MA-Home 0.3s forwards;
}

@keyframes MA-Home {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: scale(0.2) translate(100%, -100%); 
    opacity: 0;
  }
}


.ma-message-app {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f9fafb;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  margin-top: 10%;
  padding: 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e7eb;
}

.header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

/* ユーザー選択画面 */
.user-selection {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s;
  background-color: #f9fafb;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

    .user-list {
      overflow-y: auto;
      flex: 1;
    }

    .user-item {
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid #e5e7eb;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .user-item:hover {
      background-color: #f9fafb;
    }

    .user-avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    .user-info {
      margin-left: 16px;
      flex: 1;
    }

    .user-info h2 {
      font-size: 1.125rem;
      font-weight: 600;
      color: #1f2937;
      margin: 0;
    }

    .user-info p {
      font-size: 0.875rem;
      color: #6b7280;
      margin: 4px 0 0;
    }

/* チャット画面 */
.chat-screen {
  position: relative;
  height: 97%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  background-color: #f9fafb;
}

/* アニメーションを適用 */
.slide-in {
  animation: slideIn 0.3s ease-out forwards;
}

.slide-out {
  animation: slideOut 0.3s ease-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* スライドアウトアニメーション */
@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.chat-header {
  position: relative;
  padding: 16px;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 追加 */
}

.MAback-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.back-button img {
  width: 24px;
  height: 24px;
}

.chat-header-info {
  margin-top: 10%;
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.chat-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.chat-header-info span {
  margin-left: 8px;
  font-weight: 600;
  color: #1f2937;
}

.chat-messages {
  position: relative;
  height: 10%;
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #d8d8d8;
}

.chat-message {
  display: flex;
  margin-bottom: 16px;
}

.chat-message.sent {
  justify-content: flex-end;
}

.chat-message.received {
  justify-content: flex-start;
}

.message-bubble {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 16px;
}

.chat-message.sent .message-bubble {
  background-color: #3b82f6;
  color: #ffffff;
  border-top-right-radius: 4px;
}

.chat-message.received .message-bubble {
  background-color: #ffffff;
  color: #1f2937;
  border-top-left-radius: 4px;
  border: 1px solid #e5e7eb;
}

.timestamp {
  font-size: 0.75rem;
  margin-top: 4px;
  color: #93c5fd;
}

.chat-message.received .timestamp {
  color: #6b7280;
}

.chat-input-container {
  position: relative;
  width: 93%;
  /* bottom: 15%; */
  padding: 16px;
  background-color: #f9fafb;
  border-top: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
}

.chat-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #d1d5db;
  border-radius: 9999px;
  outline: none;
}

.chat-input:focus {
  border-color: #3b82f6;
}

.send-button {
  margin-left: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.send-button img {
  width: 24px;
  height: 24px;
}

.MA-footer{
  position: absolute;
  width: 100%;
  bottom: 0%;
  height: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 10;
  /* background-color: #f9fafb; */
  background-color: #5b6672;
}

.new-message-dot {
  display: inline-block;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  margin-left: 5px;
}
