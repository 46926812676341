.phone-call-container {
    position: absolute;
    top: 0;
    left: 0;  
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    z-index: 100;
  }
  
  .call-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    z-index: 1000;
    cursor: pointer;
  }
  

  .call-ui {
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .call-ui p {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  button {
    outline: none; /* フォーカス時のアウトラインを非表示 */
    border: none; /* ボーダーを非表示 */
    background: none; /* 背景色を削除 */
    padding: 0; /* 余白をリセット */
    margin: 0; /* マージンをリセット */
    cursor: pointer;
  }  

  .call-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .call-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }
  
  .call-button.disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  .call-button:not(.disabled) {
    background-color: #28a745;
  }
  
  .call-button.in-call {
    background-color: #dc3545;
  }
  

  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .status-bar {
    text-align: center;
    margin-bottom: 20px;
  }

  .caller-name {
    position: absolute;
    top: 10%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    text-align: center;
    /* background-color: #28a745; */
    /* font-size: 140px; */
    font-weight: bold;
  }

  .call-timer {
    font-size: 2rem;
    margin: 20px 0;
    text-align: center;
  }
  
  .call-buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .call-button.reject {
    background-color: #d9534f;
    color: #fff;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    font-size: 1.2rem;
  }
  
  .call-answer {
    position: absolute;
    bottom: 20%;
    right: 20%;
    background: rgba(21, 255, 0, 0.8); /* 背景を白く設定 */
    border-radius: 50%; /* 丸いボタン */
    width: 80px; /* 幅 */
    height: 80px; /* 高さ */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    backdrop-filter: blur(10px); /* ブラー効果 */
  }

  .call-answer::before {
    content: '';
    display: block;
    width: 50%; /* SVGの幅を調整 */
    height: 50%; /* SVGの高さを調整 */
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100%25" height="100%25" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath d="M20.9996 8V3M20.9996 3H15.9996M20.9996 3L14.9996 9M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E') no-repeat center;
    background-size: contain;
  }

  .call-end-container.incoming .call-end {
    position: absolute;
    bottom: 20%;
    left: 20%;
  }
  
  .call-end-container.in-call .call-end {
    position: absolute;
    top: 80%;
    right: 10%;
  }
  
  .call-end {
    background: rgba(255, 0, 0, 0.8); /* 背景を白く設定 */
    border-radius: 50%; /* 丸いボタン */
    width: 80px; /* 幅 */
    height: 80px; /* 高さ */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    backdrop-filter: blur(10px); /* ブラー効果 */
  }
  
  .call-end::before {
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    color: #fff;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath d="M5.47542 12.8631C4.44449 11.2622 3.67643 9.54121 3.17124 7.76131C3.01103 7.19687 2.93093 6.91464 2.9297 6.5017C2.92833 6.04347 3.08969 5.42298 3.31412 5.02348C3.51636 4.66345 3.78117 4.39863 4.3108 3.86901L4.46843 3.71138C4.99987 3.17993 5.2656 2.91421 5.55098 2.76987C6.11854 2.4828 6.7888 2.4828 7.35636 2.76987C7.64174 2.91421 7.90747 3.17993 8.43891 3.71138L8.63378 3.90625C8.98338 4.25585 9.15819 4.43066 9.27247 4.60643C9.70347 5.26932 9.70347 6.1239 9.27247 6.7868C9.15819 6.96257 8.98338 7.13738 8.63378 7.48698C8.51947 7.60129 8.46231 7.65845 8.41447 7.72526C8.24446 7.96269 8.18576 8.30695 8.26748 8.58731C8.29048 8.6662 8.32041 8.72855 8.38028 8.85324C8.50111 9.10491 8.62956 9.35383 8.76563 9.59967M11.1817 12.8179L11.2266 12.8631C12.4282 14.0646 13.7869 15.0134 15.2365 15.7094C15.3612 15.7693 15.4235 15.7992 15.5024 15.8222C15.7828 15.904 16.127 15.8453 16.3644 15.6752C16.4313 15.6274 16.4884 15.5702 16.6027 15.4559C16.9523 15.1063 17.1271 14.9315 17.3029 14.8172C17.9658 14.3862 18.8204 14.3862 19.4833 14.8172C19.6591 14.9315 19.8339 15.1063 20.1835 15.4559L20.3783 15.6508C20.9098 16.1822 21.1755 16.448 21.3198 16.7333C21.6069 17.3009 21.6069 17.9712 21.3198 18.5387C21.1755 18.8241 20.9098 19.0898 20.3783 19.6213L20.2207 19.7789C19.6911 20.3085 19.4263 20.5733 19.0662 20.7756C18.6667 21 18.0462 21.1614 17.588 21.16C17.1751 21.1588 16.8928 21.0787 16.3284 20.9185C13.295 20.0575 10.4326 18.433 8.04466 16.045L7.99976 16M20.9996 3L2.99961 21" /%3E%3C/svg%3E') no-repeat center;
    background-size: contain;
  }
  
  @keyframes typewriter {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animated-text {
    max-width: 80%; /* 表示する幅を調整 */
    margin: 0 auto; /* 中央揃え */
    text-align: center; /* テキストを中央揃え */
    word-wrap: break-word; /* 長い単語を適切に折り返す */
    font-size: 1rem; /* 必要に応じてフォントサイズを調整 */
    line-height: 1.5; /* 行間を調整 */
  }
  
  .animated-text span {
    display: inline-block;
    opacity: 0;
    animation: typewriter 0.1s ease-in-out forwards;
  }
  
  
  .call-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px 0;
  }


  .option {
    display: flex;
    flex-direction: column; /* 上下に配置 */
    align-items: center; /* 中央揃え */
    justify-content: center; /* 中央揃え */
    background: transparent; /* 背景を透明に設定 */
    width: 80px; /* ボタン全体の幅 */
    height: 100px; /* ボタン全体の高さ */
    text-align: center;
    color: #fff;    
  }
    
    /* アイコンの表示領域だけを定義 */
    .icon {
        display: flex;
        align-items: center; /* 垂直方向の中央揃え */
        justify-content: center; /* 水平方向の中央揃え */
        background: rgba(255, 255, 255, 0.342); /* 背景を白に変更 */
        backdrop-filter: blur(10px); /* ブラー効果を追加 */
        -webkit-backdrop-filter: blur(10px); /* Safari対応 */
        border-radius: 50%;
        width: 60px;
        height: 60px;
        /* background-size: contain; */
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 5px; /* 名前との間隔 */
      }
      

  .option span {
    font-size: 0.8rem; /* 名前のフォントサイズを調整 */
  }
  
  .option.mute .icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 9.4V5C15 3.34315 13.6569 2 12 2C10.8224 2 9.80325 2.67852 9.3122 3.66593M12 19V22M12 19C8.13401 19 5 15.866 5 12V10M12 19C15.866 19 19 15.866 19 12V10M8 22H16M2 2L22 22M12 15C10.3431 15 9 13.6569 9 12V9L14.1226 14.12C13.5796 14.6637 12.8291 15 12 15Z'%3E%3C/path%3E%3C/svg%3E");
  }
  
  .option.keypad .icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 10H6.01M8 14H8.01M10 10H10.01M12 14H12.01M14 10H14.01M16 14H16.01M18 10H18.01M5.2 18H18.8C19.9201 18 20.4802 18 20.908 17.782C21.2843 17.5903 21.5903 17.2843 21.782 16.908C22 16.4802 22 15.9201 22 14.8V9.2C22 8.0799 22 7.51984 21.782 7.09202C21.5903 6.71569 21.2843 6.40973 20.908 6.21799C20.4802 6 19.9201 6 18.8 6H5.2C4.07989 6 3.51984 6 3.09202 6.21799C2.71569 6.40973 2.40973 6.71569 2.21799 7.09202C2 7.51984 2 8.07989 2 9.2V14.8C2 15.9201 2 16.4802 2.21799 16.908C2.40973 17.2843 2.71569 17.5903 3.09202 17.782C3.51984 18 4.0799 18 5.2 18Z'%3E%3C/path%3E%3C/svg%3E");
  }
  
  .option.speaker .icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='11 5 6 9 2 9 2 15 6 15 11 19 11 5'%3E%3C/polygon%3E%3Cpath d='M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07'%3E%3C/path%3E%3C/svg%3E");
  }
  
  .option.add-call .icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  }
  
  .option.facetime .icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z'%3E%3C/path%3E%3Cpath d='M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z'%3E%3C/path%3E%3C/svg%3E");
  }
  
  .option.contacts .icon{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z'%3E%3C/path%3E%3C/svg%3E");
  }
  