.match-id-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px; */
  z-index: 10;
}

.match-id-header {
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #282c34;
  color: rgb(255, 255, 255);
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.match-id-header h1 {
  margin: 0;
  font-size: 24px;
}

.photo-grid-ID {
  position: absolute;
  top: 13%;
  max-height: 70vh; 
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll; /* 縦スクロールを有効にする */
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.9);
}


.match-id-body {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* margin-top: 20px; */
  animation: slide-in-R 0.3s ease-in-out; /* アニメーションの変更 */
}

.profile-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 2px solid #ddd;
}

.profile-details {
  text-align: center;
  width: 100%;
}

.profile-details h2 {
  margin: 10px 0;
  font-size: 22px;
  color: #333;
}

.profile-details p {
  margin: 5px 0;
  font-size: 16px;
  color: #666;
}

.profile-details input {
  display: block;
  margin: 10px auto;
  padding: 8px;
  font-size: 16px;
  width: 90%;
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.profile-details button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-details button:hover {
  background-color: #0056b3;
}

#file-upload-button {
  margin-bottom: 3%;
  background-color: #ccc;
  color: #333;
}

#file-upload-button:hover {
  background-color: #aaa;
}

@media (max-width: 768px) {
  .photo-grid-ID {
    position: absolute;
    top: 13%;
    max-height: 55vh; 
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll; /* 縦スクロールを有効にする */
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.9);
  }
}